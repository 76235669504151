<template>
    <b-modal id="modal" :title="data.name_cs" no-close-on-backdrop
             no-close-on-esc
             size="lg"
             @close="onReset()"
    >
        <b-form v-if="data.id" id="form1" autocomplete="off" @reset="onReset" @submit.prevent="onSubmit">
            
            
            <b-row>
                
                <div class="col-12">
                    <h3>{{$t('projects.form.ttl_1')}}</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>Název projektu</label>
                            <b-form-input v-model="data.name_cs"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>Projekttitel</label>
                            <b-form-input v-model="data.name_de"></b-form-input>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>
                                1.1. Zaměření projektu | Projektfokus
                            </label>
                            <b-form-select v-model="data.project_type" :options="selectsOptions.projectType"></b-form-select>
                        </div>
                    </div>
                </div>
                <hr class="w-100">
                <div class="col-12">
                    <h3>{{$t('projects.form.ttl_2')}}</h3>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>2.1. Název žadatele | Name des Antragstellers</label>
                            <b-form-input v-model="data.user_name"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>2.2. Adresa žadatele (Ulice, č.p., PSČ, obec) | Anschrift des Antragstellers (Strasse, Hausnummer, PLZ, Ort)</label>
                            <b-form-input v-model="data.user_address" ></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                          <label>2.3. Region a země žadatele | Region und Land des Antragstellers</label>
                          <div class="row">
                            <div class="col-12 col-md-7">
                              <b-form-select v-model="data.user_region" :options="selectsOptions.region"></b-form-select>
                            </div>
                            <div class="col-12 col-md-5">
                              <b-form-select v-model="data.user_land" :options="selectsOptions.land"></b-form-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>2.4. Webové stránky | Website</label>
                            <b-form-input v-model="data.user_web"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>2.5. IČO/DIČ | ATU/ZVR</label>
                            <b-form-input v-model="data.user_ico"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                2.6. Bankovní spojení | Bankverbindung
                            </label>
                            <div class="row mb-3">
                              <!--
                                <div class="col-12 col-md-4">
                                    <label>{{$t('projects.form.ph_2_4_number')}}</label>
                                    <b-form-input v-model="data.user_bank_number" ></b-form-input>
                                </div>
                                <div class="col-12 col-md-2">
                                    <label>{{$t('projects.form.ph_2_4_code')}}</label>
                                    <b-form-input v-model="data.user_bank_code" ></b-form-input>
                                </div>
                                -->
                                <div class="col-12">
                                    <label>{{$t('projects.form.ph_2_4_name')}}</label>
                                    <b-form-input v-model="data.user_bank_name" ></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_4_iban')}}</label>
                                    <b-form-input v-model="data.user_bank_iban" ></b-form-input>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_4_bic_swift')}}</label>
                                    <b-form-input v-model="data.user_bank_bic_swift" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                2.7. Statutární zástupce | Zeichnungsberechtigte Person<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label>{{$t('projects.form.ph_2_8_contact')}}</label>
                                    <b-form-input v-model="data.user_statutor_name"></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funkce</label>
                                  <b-form-input v-model="data.user_statutor_function_cs"></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funktion</label>
                                  <b-form-input v-model="data.user_statutor_function_de"></b-form-input>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.user_statutor_tel" ></b-form-input>
                                </div>
            
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.user_statutor_email" type="email"></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                2.8. Kontaktní osoba | Kontaktperson<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label >{{$t('projects.form.ph_2_8_contact')}}</label>
                                    <b-form-input v-model="data.user_contact_name" ></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label >{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.user_contact_tel" ></b-form-input>
                                </div>
        
                                <div class="col-12 col-md-6">
                                    <label >{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.user_contact_email" type="email" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                2.9. Další údaje | Sonstige Angaben<br>
                            </label>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <b-form-checkbox
                                        v-model="data.user_vat"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_2_6_a')}}
                                    </b-form-checkbox>

                                    <b-form-checkbox
                                        v-model="data.user_prev_experience"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_2_6_c')}}
                                    </b-form-checkbox>
    
                                    <b-form-checkbox
                                        v-model="data.user_advantage"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_2_6_d')}}
                                        <span id="popover-2-6-d"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                                        <b-popover target="popover-2-6-d" triggers="hover focus">
                                            {{$t('projects.popovers.other_data_4')}}
                                        </b-popover>
                                    </b-form-checkbox>

                                    <b-form-checkbox
                                        v-model="data.user_flat_rate"
                                        :value="1"
                                        class="mt-2"
                                    >
                                      {{$t('projects.form.check_2_6_e')}}

                                    </b-form-checkbox>

                                    <p class="mt-2 pl-4" v-if="data.user_flat_rate == 1">{{$t('projects.form.check_2_6_f')}}</p>

                                    
                                </div>
                                <div class="col-12">
                                    <label>
                                        Právní forma žadatele / Rechtsform des Antragstellers&nbsp;
                                        <span id="popover-2-7"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                                        <b-popover target="popover-2-7" triggers="hover focus">
                                            {{$t('projects.popovers.legal_form')}}
                                        </b-popover>
                                    </label>
                                    <b-form-input v-model="data.user_legal_form"></b-form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="w-100">
                <div class="col-12">
                    <h3>3.A {{$t('projects.form.ttl_3')}} 1</h3>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>3.1. Název partnera | Name des Projektpartners</label>
                            <b-form-input v-model="data.partner_1_name"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                          <label>3.2. Adresa a země partnera (Ulice, č.p., PSČ, obec) | Adresse und Land des Partners (Strasse, Hausnummer, PLZ, Ort)</label>
                          <div class="row">
                            <div class="col-12 col-md-7">
                              <b-form-input v-model="data.partner_1_address" ></b-form-input>
                            </div>
                            <div class="col-12 col-md-5">
                              <b-form-select v-model="data.partner_1_land" :options="selectsOptions.land"></b-form-select>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 form-group">
                            <label>3.3. Webové stránky | Website</label>
                            <b-form-input v-model="data.partner_1_web"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>3.4. IČO/DIČ | ATU/ZVR</label>
                            <b-form-input v-model="data.partner_1_ico"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.5. Statutární zástupce | Zeichnungsberechtigte Person<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label>{{$t('projects.form.ph_2_8_contact')}}</label>
                                    <b-form-input v-model="data.partner_1_statutor_name" ></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funkce</label>
                                  <b-form-input v-model="data.partner_1_statutor_function_cs"></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funktion</label>
                                  <b-form-input v-model="data.partner_1_statutor_function_de"></b-form-input>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.partner_1_statutor_tel" ></b-form-input>
                                </div>
            
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.partner_1_statutor_email" type="email" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.6. Kontaktní osoba | Kontaktperson<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label>{{$t('projects.form.ph_2_5_contact')}}</label>
                                    <b-form-input v-model="data.partner_1_contact_name" ></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.partner_1_contact_tel" ></b-form-input>
                                </div>
            
                                <div class="col-12 col-md-6">
                                    <label >{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.partner_1_contact_email" type="email" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.7. Další údaje / Sonstige Angaben<br>
                            </label>
                            <div class="row">
                                <div class="col-12 form-group">

                                    <b-form-checkbox
                                        v-model="data.partner_1_prev_experience"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_3_7_experience_crossborder')}}
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-model="data.partner_1_own_application"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_3_7_own_application')}}
                                    </b-form-checkbox>
                                    
                                </div>
                                <div class="col-12 form-group">
                                    <label>Právní forma partnera / Rechtsform des Partners&nbsp;
                                    <span id="popover-3-6"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                                    <b-popover target="popover-3-6" triggers="hover focus">
                                        {{$t('projects.popovers.legal_form_2')}}
                                    </b-popover></label>
                                    <b-form-input v-model="data.partner_1_legal_form"></b-form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <b-button v-if="partnerBlock_2 == false && data.partner_2_name == ''" @click="partnerBlock_2 = true" type="button" size="sm" variant="primary">
                        <b-icon class="clickable" icon="plus-square-fill" variant=""></b-icon>&nbsp;
                        {{ $t('projects.form.showPartner2') }}
                        
                    </b-button>
                    <b-button v-else @click="removePartner2" type="button" size="sm" variant="danger">
                        <b-icon class="clickable" icon="trash-fill" variant=""></b-icon>&nbsp;
                        {{ $t('projects.form.removePartner2') }}
                    </b-button>
                </div>
                <div v-if="partnerBlock_2 || data.partner_2_name != '' " class="col-12">
                    <h3>3.B {{$t('projects.form.ttl_3')}} 2</h3>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label>3.B.1. Název partnera | Name des Projektpartners</label>
                            <b-form-input v-model="data.partner_2_name"></b-form-input>
                        </div>

                        <div class="col-12 form-group">
                          <label>3.B.2. Adresa a země partnera (Ulice, č.p., PSČ, obec) | Adresse und Land des Partners (Strasse, Hausnummer, PLZ, Ort)</label>
                          <div class="row">
                            <div class="col-12 col-md-7">
                              <b-form-input v-model="data.partner_2_address" ></b-form-input>
                            </div>
                            <div class="col-12 col-md-5">
                              <b-form-select v-model="data.partner_2_land" :options="selectsOptions.land"></b-form-select>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 form-group">
                            <label>3.B.3. Webové stránky | Website</label>
                            <b-form-input v-model="data.partner_2_web"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>3.B.4. IČO/DIČ | ATU/ZVR</label>
                            <b-form-input v-model="data.partner_2_ico"></b-form-input>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.B.5. Statutární zástupce | Zeichnungsberechtigte Person<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label>{{$t('projects.form.ph_2_8_contact')}}</label>
                                    <b-form-input v-model="data.partner_2_statutor_name" ></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funkce</label>
                                  <b-form-input v-model="data.partner_2_statutor_function_cs"></b-form-input>
                                </div>
                                <div class="col-6 mb-3">
                                  <label>Funktion</label>
                                  <b-form-input v-model="data.partner_2_statutor_function_de"></b-form-input>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.partner_2_statutor_tel" ></b-form-input>
                                </div>
            
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.partner_2_statutor_email" type="email" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.B.6. Kontaktní osoba | Kontaktperson<br>
                            </label>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label>{{$t('projects.form.ph_2_5_contact')}}</label>
                                    <b-form-input v-model="data.partner_2_contact_name" ></b-form-input>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_tel')}}</label>
                                    <b-form-input v-model="data.partner_2_contact_tel" ></b-form-input>
                                </div>
            
                                <div class="col-12 col-md-6">
                                    <label>{{$t('projects.form.ph_2_5_email')}}</label>
                                    <b-form-input v-model="data.partner_2_contact_email" type="email" ></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                3.B.7. Další údaje / Sonstige Angaben<br>
                            </label>
                            <div class="row">
                                <div class="col-12 form-group">
                        
                                    <b-form-checkbox
                                        v-model="data.partner_2_prev_experience"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_3_7_experience_crossborder')}}
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-model="data.partner_2_own_application"
                                        :value="1"
                                        class="mt-2"
                                    >
                                        {{$t('projects.form.check_3_7_own_application')}}
                                    </b-form-checkbox>
                    
                                </div>
                                <div class="col-12 form-group">
                                    <label>Právní forma partnera / Rechtsform des Partners&nbsp;
                                        <span id="popover-3-6-B"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                                        <b-popover target="popover-3-6-B" triggers="hover focus">
                                            {{$t('projects.popovers.legal_form')}}
                                        </b-popover>
                                    </label>
                                    <b-form-input v-model="data.partner_2_legal_form"></b-form-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <hr class="w-100">
                <div class="col-12">
                    <h3>4. {{$t('projects.form.ttl_4')}}</h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>4.1. Název projektu</label>
                            <b-form-input v-model="data.name_cs"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.1. Projekttitel</label>
                            <b-form-input v-model="data.name_de"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>Zkratka názvu projektu</label>
                            <b-form-input v-model="data.name_shortcut_cs"></b-form-input>
                        </div>
                        <div class="col-6 form-group">
                            <label>Kurzprojekttitel</label>
                            <b-form-input v-model="data.name_shortcut_de"></b-form-input>
                        </div>
                        <div class="col-12">
                            <label>4.2. {{$t('projects.form.project_duration')}}</label>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                {{$t('projects.form.scheduledStart')}}<br>
                            </label>
                            <b-form-datepicker v-model="data.project_start.date" class="mb-2"></b-form-datepicker>
                        </div>
                        <div class="col-12 form-group">
                            <label>
                                {{$t('projects.form.scheduledEnd')}}
                            </label>
                            <b-form-datepicker v-model="data.project_end.date" class="mb-2"></b-form-datepicker>
                        </div>
                        
                        <div class="col-6">
                            <label>4.3. Popis projektu</label>
                        </div>
                        <div class="col-6">
                            <label>4.3. Beschreibung des Projektes</label>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>4.3.1 Cíle projektu
                                <small>(max. 700 znaků)</small>
                            </label>
                            <b-textarea v-model="data.project_goals_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.3.1 Projektziele
                                <small>(max. 900 Zeichen)</small>
                            </label>
                            <b-textarea v-model="data.project_goals_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>4.3.2 Obsah projektu
                              <small>(max. 1000 znaků)</small>
                            </label>
                            <b-textarea v-model="data.project_description_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.3.2 Projektinhalt
                              <small>(max. 1200 Zeichen)</small>
                            </label>
                            <b-textarea v-model="data.project_description_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>4.3.3 Cílové skupiny
                                <small>(max. 300 znaků)</small>
                            </label>
                            <b-textarea v-model="data.project_target_groups_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.3.3 Zielgruppen
                              <small>(max. 400 Zeichen)</small>
                            </label>
                          <b-textarea v-model="data.project_target_groups_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>4.3.4 Přeshraniční dopad projektu<br>
                                <small>(max. 700 znaků)</small></label>
                            <b-textarea v-model="data.project_crossborder_impact_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.3.4 Grenzüberschreitende Wirkung des Projektes<br><small>(max. 900 Zeichen)</small></label>
                            <b-textarea v-model="data.project_crossborder_impact_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        
                        <div class="col-6 form-group">
                            <label>4.3.5 Udržitelnost projektu <small>(max. 350 znaků)</small></label>
                            <b-textarea v-model="data.project_sustainability_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>4.3.5 Dauerhaftigkeit des Projektes <small>(max. 500 Zeichen)</small></label>
                            <b-textarea v-model="data.project_sustainability_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                          <label style="min-height: 42px">4.3.6 Územní dopad projektu</label>
                          <b-textarea v-model="data.project_impact_cz_cs" class="mb-2" placeholder="Česko" max-rows="8" rows="2"></b-textarea>
                          <b-textarea v-model="data.project_impact_at_cs" max-rows="8" placeholder="Rakousko" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                          <label style="min-height: 42px">4.3.6 Räumlicher Wirkungsbereich des Projektes</label>
                          <b-textarea v-model="data.project_impact_cz_de" class="mb-2" placeholder="Tschechien" max-rows="8" rows="2"></b-textarea>
                          <b-textarea v-model="data.project_impact_at_de" max-rows="8" placeholder="Österreich" rows="2"></b-textarea>
                        </div>
                      <!--
                        <div class="col-12 form-group">
                          <label>4.3.7 Typové aktivity projektu - vyplňují jen čeští žadatelé | Aktivitätstypen - nur für tschechische Antragsteller
                          </label>
                          <div class="row">
                            <project-activities :type="0" ref="project_activities" :id-article="data.id"></project-activities>
                          </div>

                        </div>
                      -->
                        <div class="col-12 form-group">
                          <label>4.3.7 Milníky projektu  | Projektmeilensteine
                          </label>
                          <div class="row">
                            <project-activities :type="1" ref="project_activities_2" :id-article="data.id"></project-activities>
                          </div>

                        </div>
                        <div class="col-12">
                          <label>4.4. {{$t('projects.form.project_result')}} <span id="popover-4_2"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span></label>
                          <b-popover target="popover-4_2" triggers="hover focus">
                            {{$t('projects.popovers.results')}}
                          </b-popover>
                        </div>
                        <template v-if="data.project_type == 1">

                        <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_77')}}</label>
                            <b-form-input v-model="data.rco_77" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_77 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 77</label>
                              <b-textarea v-model="data.text_rco_77" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_77 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 77</label>
                              <b-textarea v-model="data.text_rco_77_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_77 > 0">
                            <div v-if="data.rco_77 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_77')}}</label>
                              <b-form-input v-model="data.rcr_77" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_77 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 77</label>
                              <b-textarea v-model="data.text_rcr_77" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_77 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 77</label>
                              <b-textarea v-model="data.text_rcr_77_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                          <hr class="w-100">
                          <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_83')}}</label>
                            <b-form-input v-model="data.rco_83" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_83 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 83</label>
                              <b-textarea v-model="data.text_rco_83" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_83 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 83</label>
                              <b-textarea v-model="data.text_rco_83_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_83 > 0">
                            <div v-if="data.rco_83 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_79')}}</label>
                              <b-form-input v-model="data.rcr_79" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_79 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 79</label>
                              <b-textarea v-model="data.text_rcr_79" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_79 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 79</label>
                              <b-textarea v-model="data.text_rcr_79_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                          <hr class="w-100">
                          <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_87')}}</label>
                            <b-form-input v-model="data.rco_87" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_87 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 87</label>
                              <b-textarea v-model="data.text_rco_87" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_87 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 87</label>
                              <b-textarea v-model="data.text_rco_87_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_87 > 0">
                            <div v-if="data.rco_87 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_84')}}</label>
                              <b-form-input v-model="data.rcr_84" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_84 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 84</label>
                              <b-textarea v-model="data.text_rcr_84" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_84 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 84</label>
                              <b-textarea v-model="data.text_rcr_84_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                          <hr class="w-100">
                          <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_116')}}</label>
                            <b-form-input v-model="data.rco_116" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_116 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 116</label>
                              <b-textarea v-model="data.text_rco_116" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_116 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 116</label>
                              <b-textarea v-model="data.text_rco_116_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_116 > 0">
                            <div v-if="data.rco_116 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_104')}}</label>
                              <b-form-input v-model="data.rcr_104" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_104 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 104</label>
                              <b-textarea v-model="data.text_rcr_104" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_104 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 104</label>
                              <b-textarea v-model="data.text_rcr_104_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                        </template>
                        <template v-else>
                          <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_81')}}</label>
                            <b-form-input v-model="data.rco_81" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_81 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 81</label>
                              <b-textarea v-model="data.text_rco_81" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_81 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 81</label>
                              <b-textarea v-model="data.text_rco_81_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_81 > 0">
                            <div v-if="data.rco_81 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_85')}}</label>
                              <b-form-input v-model="data.rcr_85_81" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_85_81 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 85</label>
                              <b-textarea v-model="data.text_rcr_85_81" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_85_81 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 85</label>
                              <b-textarea v-model="data.text_rcr_85_81_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                          <hr class="w-100">
                          <!-- Group -->
                          <div class="col-12 form-group">
                            <label>{{$t('projects.results.rco_115')}}</label>
                            <b-form-input v-model="data.rco_115" type="number" min="0"></b-form-input>
                          </div>
                          <template v-if="data.rco_115 > 0">
                            <div  class="col-6 form-group">
                              <label>Okomentování cílové hodnoty (max. 300 znaků) - RCO 115</label>
                              <b-textarea v-model="data.text_rco_115" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rco_115 > 0" class="col-6 form-group">
                              <label>Beschreibung des Zielwertes (max. 400 Zeichen) - RCO 115</label>
                              <b-textarea v-model="data.text_rco_115_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <template v-if="data.rco_115 > 0">
                            <div v-if="data.rco_115 > 0" class="col-12 form-group">
                              <label class="color-blue">{{$t('projects.results.rcr_85')}}</label>
                              <b-form-input v-model="data.rcr_85_115" type="number" min="0"></b-form-input>
                            </div>
                            <div v-if="data.rcr_85_115 > 0" class="col-6 form-group">
                              <label class="color-blue">Okomentování cílové hodnoty (max. 300 znaků) - RCR 85</label>
                              <b-textarea v-model="data.text_rcr_85_115" max-rows="8" rows="2"></b-textarea>
                            </div>
                            <div v-if="data.rcr_85_115 > 0" class="col-6 form-group">
                              <label class="color-blue">Beschreibung des Zielwertes (max. 400 Zeichen) - RCR 85</label>
                              <b-textarea v-model="data.text_rcr_85_115_de" max-rows="8" rows="2"></b-textarea>
                            </div>
                          </template>
                          <!-- END Group -->
                          <hr class="w-100">
                        </template>
                    </div>
                </div>
                <div class="col-12">
                    <h3>5. {{$t('projects.form.ttl_5')}}
                        <small id="popover-5"><b-icon aria-hidden="true" icon="info-circle"></b-icon></small>
                        <b-popover target="popover-5" triggers="hover focus">
                            {{$t('projects.popovers.cross_border')}}
                        </b-popover>
                    </h3>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label>5.1. Společná příprava projektu
                                <small>(max. 500 znaků)</small>
                            </label>
                            <b-textarea v-model="data.quality_common_preparation_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.1. Gemeinsame Vorbereitung
                                <small>(max. 650 Zeichen)</small>
                            </label>
                            <b-textarea v-model="data.quality_common_preparation_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.2. Společná realizace projektu
                                <small>(max. 500 znaků)</small>
                            </label>
                            <b-textarea v-model="data.quality_joint_realization_cs" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.2. Gemeinsame Umsetzung
                                <small>(max. 650 Zeichen)</small>
                            </label>
                            <b-textarea v-model="data.quality_joint_realization_de" max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.3. Společné financování</label>
                            <b-form-checkbox
                                v-model="data.quality_joint_financing_checkbox"
                                @change="calcFinancePartner()"
                                :value="1"
                            > ANO
                            </b-form-checkbox>
                            <label v-if="data.quality_joint_financing_checkbox">Popis (max. 500 znaků)</label>
                            <b-textarea v-if="data.quality_joint_financing_checkbox"
                                        v-model="data.quality_joint_financing_cs"
                                        max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.3. Gemeinsame Finanzierung</label>
                            <b-form-checkbox
                                v-model="data.quality_joint_financing_checkbox"
                                @change="calcFinancePartner()"
                                :value="1"
                            > JA
                            </b-form-checkbox>
                          <label v-if="data.quality_joint_financing_checkbox">Beschreibung (max. 650 Zeichen)</label>
                            <b-textarea v-if="data.quality_joint_financing_checkbox"
                                        v-model="data.quality_joint_financing_de"
                                        max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.4. Společný personál</label>
                            <b-form-checkbox
                                v-model="data.quality_common_staff_checkbox"
                                :value="1"
                            > ANO
                            </b-form-checkbox>
                            <label v-if="data.quality_common_staff_checkbox">Popis (max. 500 znaků)</label>
                            <b-textarea v-if="data.quality_common_staff_checkbox"
                                        v-model="data.quality_common_staff_cs"
                                        max-rows="8" rows="2"></b-textarea>
                        </div>
                        <div class="col-6 form-group">
                            <label>5.4. Gemeinsames Personal</label>
                            <b-form-checkbox
                                v-model="data.quality_common_staff_checkbox"
                                :value="1"
                            > JA
                            </b-form-checkbox>
                            <label v-if="data.quality_common_staff_checkbox">Beschreibung (max. 650 Zeichen)</label>
                            <b-textarea v-if="data.quality_common_staff_checkbox"
                                        v-model="data.quality_common_staff_de"
                                        max-rows="8" rows="2"></b-textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <h3>6. {{$t('projects.form.ttl_6')}}</h3>

                    <div class="row">
                      <div class="col-12 form-group">
                        <label>6.1. Rozpočet projektu | Projektkostenplan <span id="popover-6-1"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                          <b-popover target="popover-6-1" triggers="hover focus">
                            {{$t('projects.popovers.budget')}}
                          </b-popover></label>

                        <div class="row mb-2">
                          <div class="col-7">
                            <b><label>6.1.1 Rozpočet žadatele | Kostenplan des Antragstellers</label></b>
                          </div>
                          <div class="col-5 text-right">
                            <b>{{$t('projects.form.finance_amount')}}</b>
                          </div>
                        </div>
                        <!--
                        <div class="row mb-2">
                          <div class="col-7">
                            <b>{{$t('projects.form.unit_cost')}}</b>
                          </div>
                          <div class="col-5 text-right">
                            <b-form-input v-model="data.budget_unit_cost" step="0.01" @input="calcFinance()" type="number"></b-form-input>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-7">
                            <b>{{$t('projects.form.draft_budget')}}</b>
                          </div>
                          <div class="col-5">
                            <b-form-input v-model="data.budget_draft" step="0.01" @input="calcFinance()" type="number"></b-form-input>
                          </div>
                        </div>

                        <div class="row mb-2">
                          <div class="col-7">
                            <b>{{$t('projects.form.total_budget')}}</b>
                          </div>
                          <div class="col-5">
                            <b-form-input :value="(data.finance_total_expenses).toFixed(2)" step="0.01" type="number" readonly></b-form-input>
                          </div>
                        </div>
                        -->
                        <!-- toto (finance_total_expenses) je místo celé zakomentované tabulky, později zase vyměnit @TODO change_2 -->
                        <div class="row mb-2">
                          <div class="col-7">
                            <b>{{$t('projects.form.total_budget')}}</b>
                          </div>
                          <div class="col-5">
                            <b-form-input v-model="data.unit_cost" step="0.01" type="number" :value="0" hidden="" ></b-form-input>
                            <b-form-input v-model="data.budget_draft" step="0.01" type="number" @input="calcFinance()" ></b-form-input>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row">
                        <div class="col-12 form-group">
                            <label>6.2. Plán financování | Finanzierungsplan</label>
                            <div class="row mb-2">
                                <div class="col-7">
                                    <b>{{$t('projects.form.finance_resources')}}</b>
                                </div>
                                <div class="col-2 text-right">
                                    <b>{{$t('projects.form.finance_percent')}}</b>
                                </div>
                                <div class="col-3 text-right">
                                    <b>{{$t('projects.form.finance_amount')}}</b>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-7">
                                    {{$t('projects.form.finance_1')}}
                                </div>
                                <div class="col-2 text-right">
                                    100 %
                                </div>
                                <div class="col-3">
                                    <b-form-input :value="data.finance_total_expenses.toFixed(2)" step="0.01" type="number" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-7">
                                    {{$t('projects.form.finance_2')}}
                                </div>
                                <div class="col-2 text-right">
                                  <b-form-input v-model="data.finance_from_efrr_percent" step="1" max="80" @input="calcFinance()" type="number"></b-form-input>
                                </div>
                                <div class="col-3">
                                  <b-form-input :value="data.finance_from_efrr.toFixed(2)"  type="number" readonly></b-form-input>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-7">
                                    {{$t('projects.form.finance_3')}}
                                </div>
                                <div class="col-2 text-right">
                                  <b-form-input :value="(100 - data.finance_from_efrr_percent).toFixed(2)"  type="number" readonly=""></b-form-input>
                                </div>
                                <div class="col-3">
                                    <b-form-input :value="(data.finance_from_contributor.toFixed(2))" type="number" readonly></b-form-input>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-12">
                                    <b>{{$t('projects.form.finance_contribution')}}</b>
                                </div>
                            </div>
                          <!--
                            <div class="row mb-2">
                                <div class="col-7">
                                    {{$t('projects.form.finance_contribution_1')}}
                                </div>
                                <div class="col-2">
                                    <b-form-input v-model="data.finance_other_sources_percent" step="0.01" type="number" :title="$t('projects.form.finance_percent')"></b-form-input>
                                </div>
                                <div class="col-3">
                                    <b-form-input v-model="data.finance_other_sources_amount" step="0.01" type="number" :title="$t('projects.form.finance_amount')"></b-form-input>
                                </div>
                            </div>
                            -->
                            <div class="row mb-2" v-if="data.quality_joint_financing_checkbox">
                                <div class="col-7">
                                    {{$t('projects.form.finance_contribution_2')}}
                                    <b-badge pill variant="warning" v-if="(limitPercent).toFixed(2) > Number(data.finance_partner_sources_amount).toFixed(2)">
                                      {{ $t('projects.form.finance_contribution_2_max_warning') }}
                                    </b-badge>
                                </div>
                                <div class="col-2"></div>
                                <div class="col-3">
                                    <b-form-input v-model="data.finance_partner_sources_amount"
                                                  step="0.01"
                                                  type="number"
                                                  :min="limitPercent.toFixed(2)"
                                                  :title="$t('projects.form.finance_amount')"
                                                  @input="calcFinancePartner('finance_partner_sources_amount')"
                                                  ></b-form-input>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-7">
                                    {{$t('projects.form.finance_contribution_3')}}
                                </div>
                                <div class="col-2"></div>
                                <div class="col-3">
                                    <b-form-input :value="data.finance_own_sources_amount"
                                                  step="0.01"
                                                  type="number"
                                                  readonly
                                                  :title="$t('projects.form.finance_amount')"></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 form-group">
                          <label>
                            Povinné přílohy projektové žádosti | Verpflichtende Anhänge zum Projektantrag <span id="popover-attachment"><b-icon aria-hidden="true" icon="info-circle"></b-icon></span>
                            <b-popover target="popover-attachment" triggers="hover focus">
                              {{$t('projects.popovers.attachment')}}
                            </b-popover><br>
                          </label>
                          <div class="row">
                            <div class="col-12 form-group">
                            <template v-for="(item, key) in 8" >
                              <b-form-checkbox :key="key" v-model="data['at_' + (item-1)]" value="1" >{{ $t('projects.attachment_items.at_' + (item-1)) }}</b-form-checkbox>
                            </template>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </b-row>
            
            <div v-if="data.status == ''" class="form-group">
                <b-button variant="danger" @click="deleteItem(data.id)">{{ $t('projects.form.deleteProject') }}
                    <b-icon icon="trash-fill"></b-icon>
                </b-button>
            </div>
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button class="float-left" form="form1" type="reset" variant="outline-secondary">
                    {{ $t('universalTxt.closeBtn') }}
                </b-button>
                <b-button class="float-right" form="form1" type="submit" variant="primary">
                    {{ $t('universalTxt.saveBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

import ProjectActivities from "../../components/project-activities.vue";

export default {
    name: "projects-edit",
  components: {ProjectActivities},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            data: {},
            data_activities: {},
            autoSaveInterval: null,
            local: this.$store.getters.local,
            selectsOptions: {
                projectType: [
                  {value: 1, text: this.$t('projects.form.project_type_1')},
                  {value: 2, text: this.$t('projects.form.project_type_2')}
                ],
                region: [ 'Jihočeský kraj', 'Kraj Vysočina', 'Jihomoravský kraj', 'Niederösterreich', 'Oberösterreich' ],
                land: [ 'AT', 'CZ']
            },
            partnerBlock_2: false
        }
    },
    methods: {
    
        removePartner2() {
          this.partnerBlock_2 = false;
          this.data.partner_2_name = '';
        },
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {'id': this.id},
                    method: 'GET'
                });
                
                this.data = response.data[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },

        async saveData() {
          try {
            await this.$refs.project_activities?.updateItems();
            await this.$refs.project_activities_2?.updateItems();
            await this.$http({
              url: process.env.VUE_APP_SERVER + 'api/articles/update-item.php',
              headers: {'content-type': 'application/x-www-form-urlencoded'},
              data: this.data,
              method: 'PUT'
            });

            this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
          }  catch (error) {
            console.log("error", error);
            this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
          }
        },
        async onSubmit() {
          await this.saveData();
          this.$emit('loadData');
          this.$router.go(-1);
        },

        async deleteItem(id) {
            try {
                if (confirm(this.$t('projects.deleteItem'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/articles/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {'id': id},
                        method: 'DELETE'
                    });
                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
                
            } catch (error) {
                await this.loadData();
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        onReset() {
            this.$emit('loadData');
            this.$router.go(-1);
        },
        calcFinance: function() {

          this.data.finance_total_expenses = (Number(this.data.budget_draft) + Number(this.data.budget_unit_cost));
          this.data.finance_from_efrr = (Math.round(((this.data.finance_total_expenses) * (this.data.finance_from_efrr_percent / 100) ) * 100) / 100);
          this.data.finance_from_contributor = Math.round((this.data.finance_total_expenses - this.data.finance_from_efrr) * 100) / 100;

          this.calcFinancePartner();
        },
        calcFinancePartner: function (type = null) {

          if (type !== 'finance_partner_sources_amount') {
            if (!this.data.quality_joint_financing_checkbox) {
              this.data.finance_partner_sources_amount = 0;

            } else {

              if ( (this.data.finance_partner_sources_amount < (Math.ceil((this.data.finance_total_expenses) * 0.05 * 100) / 100) ) ||
                  this.data.finance_partner_sources_amount > (this.data.finance_total_expenses)) {
                this.data.finance_partner_sources_amount = ((Math.ceil((this.data.finance_total_expenses) * 0.05 * 100) / 100)).toFixed(2);
              }

            }
          }

          this.data.finance_own_sources_amount = (((this.data.finance_from_contributor)) - this.data.finance_partner_sources_amount).toFixed(2);

        }
        
    },
    computed: {
        permissionsOptions: function () {
            return this.$store.getters.getUserRoles;
        },
        languages() {
            return this.$store.getters.langs
        },
        limitPercent: function () {
          return (Math.ceil(((this.data.finance_total_expenses) * 0.05) * 100) / 100);
        }
    },
    watch: {
      /*
      'data.budget_unit_cost': function() {
        this.calcFinance();
      },
      'data.budget_draft': function() {
        this.calcFinance();
      },
      'data.finance_from_efrr_percent': function() {
        this.calcFinance();
      },


      'data.finance_own_sources_amount': function() {
        this.calcFinancePartner();
      }

     */
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();

        this.autoSaveInterval = setInterval(() => {
          try {
            this.saveData();
            this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.automatic_save'));
          } catch (e) {
            console.log(e)
          }
        }, (1000 * 60 * 2)); // 2 minuty
    },
    created() {
    
    },
  beforeDestroy() {
    clearInterval(this.autoSaveInterval);
  }
}
</script>

<style lang="scss" scoped>
    input[type='number'] {
        text-align: right;
    }
    .color-blue {
      color: #008fd7;
    }
    
</style>
